<template>
  <div class="bg-silver">
    <!-- you can use v-breadcrumbs -> Vuetify -->
    <div class="t-breadcrumb">
      <router-link to="/home">{{ $t("title.main") }}</router-link>
      >
      {{ $route.meta.title }}
    </div>
    <h1 class="py-4 px-main text-heading--prm bg-white">
      {{ $t("title.news") }}
    </h1>
    <div class="t-container">
      <UrgentInformationItem class="urgent-information-container mb-2" />
      <!-- START List-news -->
      <v-row>
        <v-col>

          <NewsItem
            v-for="item in this.items"
            :key="item.id"
            :id="item.id"
            :title="item.title"
            :urlImg="item.imageUrl"
            :description="item.body"
            :showFromDate="item.showFromDate"
            :link="'/home/news/' + item.id"
            class="mb-3"
          ></NewsItem>

          <!-- ----------- -->
          <div class="text-center pt-3 pb-6">
            <v-btn
              elevation="0"
              rounded
              class="hover-secondary bg-white text-medium font-weight-bold px-10"
              @click="loadList()"
              :disabled="!hasMore"
            >
              {{ $t('buttons.viewMore') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- END List-news -->
    </div>
  </div>
</template>

<script>
import NewsItem from '@/components/NewsItem'
import UrgentInformationItem from '@/components/UrgentInformation'
import { paginationMixin } from '@/mixins/pagination.mixin'

export default {
  name: 'NewsList',
  mixins: [paginationMixin],
  components: {
    NewsItem,
    UrgentInformationItem
  },
  async mounted () {
    await this.loadList()
  },
  methods: {
    async loadList () {
      await this.getPaginatedList(async (apollo, skip) => {
        return (await this.$store.dispatch('myWebNotificationList', {
          apollo,
          skip,
          take: 10
        })).data.myWebNotificationList
      })
    }
  }
}
</script>
